import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import get from 'lodash.get';
import { Button } from '@devseed-ui/button';
import { themeVal, glsp, media } from '@devseed-ui/theme-provider';
import {
  CollecticonBrandGithub,
  CollecticonExpandTopRight
} from '@devseed-ui/collecticons';

import { SupHeading } from '../../styles/typography/supheading';
import { leading } from '../../styles/typography/leading';
import Constrainer from '../../styles/constrainer';
import Layout from '../../components/layout';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageMetaDetails,
  InpageTitle,
  InpageSuptitle,
  InpageBody,
  InpageLead,
  InpageActions
} from '../../styles/inpage';
import { Widont } from '../../components/widont';
import BlockLayout from '../../components/block-layout';
import Media from '../../components/media';
import InpageNavigation from '../../components/inpage-nav';
import SectionRelated from '../../components/section-related';
import { CallToAction, CTAActions } from '../../components/call-to-action';

const ProjectMeta = styled(Constrainer)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${glsp(themeVal('layout.gap.xsmall'))};

  ${media.smallUp`
    grid-gap: ${glsp(themeVal('layout.gap.small'))};
  `}

  ${media.mediumUp`
    grid-template-columns: repeat(8, 1fr);
    grid-gap: ${glsp(themeVal('layout.gap.medium'))};
  `}

  ${media.largeUp`
    grid-template-columns: repeat(12, 1fr);
    grid-gap: ${glsp(themeVal('layout.gap.large'))};
  `}

  ${media.xlargeUp`
    grid-gap: ${glsp(themeVal('layout.gap.xlarge'))};
  `}
`;

const ProjectMetaInner = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${glsp(1, themeVal('layout.gap.xsmall'))};

  ${media.smallUp`
    grid-gap: ${glsp(2, themeVal('layout.gap.small'))};
  `}

  ${media.mediumUp`
    grid-template-columns: repeat(8, 1fr);
    grid-gap: ${glsp(4, themeVal('layout.gap.medium'))};
  `}

  ${media.largeUp`
    grid-column: 2 / -1;
    grid-template-columns: repeat(11, 1fr);
    grid-gap: ${glsp(5, themeVal('layout.gap.large'))};
  `}

  ${media.xlargeUp`
    grid-gap: ${glsp(6, themeVal('layout.gap.xlarge'))};
  `}
`;

const MetaBlock = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-row-gap: ${glsp()};

  ${media.mediumUp`
    grid-row-gap: ${glsp(2)};
  `}
`;

const MetaBlockTitle = styled(SupHeading).attrs({
  as: 'h1',
  variation: 'base'
})`
  margin: 0;
`;

const MetaBlockContent = styled.div`
  ${leading};
`;

const OverviewBlock = styled(MetaBlock)`
  grid-column-end: span 4;

  ${media.mediumUp`
    grid-column-end: span 8;
  `}

  ${MetaBlockContent} {
    font-size: 1.5rem;
    line-height: 2.25rem;

    ${media.mediumUp`
      font-size: 2rem;
      line-height: 2.75rem;
    `}
  }
`;

const ChallengeBlock = styled(MetaBlock)`
  grid-column-end: span 4;

  ${media.smallUp`
    grid-column-end: span 2;
  `}

  ${media.mediumUp`
    grid-column-end: span 3;
  `}

  ${media.largeUp`
    grid-column-end: span 4;
  `}
`;

const OutcomeBlock = styled(MetaBlock)`
  grid-column-end: span 4;

  ${media.smallUp`
    grid-column-end: span 2;
  `}

  ${media.mediumUp`
    grid-column-end: span 3;
  `}

  ${media.largeUp`
    grid-column-end: span 4;
  `}
`;

const ExpertiseBlock = styled(MetaBlock)`
  grid-column-end: span 4;

  ${media.mediumUp`
    grid-column-end: span 2;
  `}

  ${media.largeUp`
    grid-column-end: span 3;
  `}
`;

const ProjectSingleTemplate = ({ data, location, pageContext }) => {
  const { previous, next } = pageContext;
  const { project } = data;
  const { html } = project;
  const {
    title,
    lead,
    client,
    teaser,
    blocks,
    overview,
    challenge,
    expertise,
    topics,
    outcome,
    media,
    public: _public,
    source,
    related
  } = project.frontmatter;

  const layoutBlocks = html
    ? [
        ...(blocks || []),
        {
          type: 'text',
          body: html
        }
      ]
    : blocks;

  const coverImage = get(media, 'cover.url.childImageSharp.fluid');
  const coverImageAttribution = get(media, 'cover.attribution');

  const hasMetaSection = !!(
    overview ||
    challenge ||
    outcome ||
    expertise.length
  );

  const hasClient = !!client.name;
  const isDevseedClient =
    hasClient && client.name.toLowerCase() === 'development seed';

  const prevPost = previous
    ? {
        url: `/projects/${previous.slug}`,
        linkTitle: 'Visit previous project',
        title: previous.frontmatter.title
      }
    : null;
  const nextPost = next
    ? {
        url: `/projects/${next.slug}`,
        linkTitle: 'Visit next project',
        title: next.frontmatter.title
      }
    : null;

  return (
    <Layout
      location={location}
      title={title}
      description={teaser}
      metaImage={coverImage && coverImage.src}
    >
      <Inpage>
        <InpageHeader leadLayout='column'>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>
                <Widont>{title}</Widont>
              </InpageTitle>
              <InpageSuptitle>
                <Link to='/projects' title='View Projects page'>
                  Projects
                </Link>
              </InpageSuptitle>
              {hasClient && (
                <InpageMetaDetails>
                  <dt>Client</dt>
                  <dd>
                    {isDevseedClient ? (
                      'Devseed Lab'
                    ) : (
                      <React.Fragment>
                        <span>With</span>{' '}
                        {client.url ? (
                          <a href={client.url} title='Visit client website'>
                            {client.name}
                          </a>
                        ) : (
                          client.name
                        )}
                      </React.Fragment>
                    )}
                  </dd>
                </InpageMetaDetails>
              )}
            </InpageHeadline>
            {lead && (
              <InpageLead>
                <p>{lead}</p>
              </InpageLead>
            )}

            {(_public?.url || source?.url) && (
              <InpageActions>
                {_public?.url && (
                  <Button
                    forwardedAs='a'
                    variation='primary-fill'
                    size='xlarge'
                    href={_public.url}
                    title='See live project'
                  >
                    <CollecticonExpandTopRight /> View live
                  </Button>
                )}
                {source?.url && (
                  <Button
                    forwardedAs='a'
                    variation='primary-outline'
                    size='xlarge'
                    href={source.url}
                    title='View on GitHub'
                  >
                    <CollecticonBrandGithub /> View the code
                  </Button>
                )}
              </InpageActions>
            )}
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody isSoftPadded={!!coverImage}>
          {coverImage && (
            <Media
              size='cover'
              decoration='bottom'
              fluid={coverImage}
              attribution={coverImageAttribution}
              alt='Project cover image'
            />
          )}
          {hasMetaSection && (
            <ProjectMeta>
              <ProjectMetaInner>
                {overview && (
                  <OverviewBlock>
                    <MetaBlockTitle>Overview</MetaBlockTitle>
                    <MetaBlockContent>
                      <p>{overview}</p>
                    </MetaBlockContent>
                  </OverviewBlock>
                )}
                {challenge && (
                  <ChallengeBlock>
                    <MetaBlockTitle>Challenge</MetaBlockTitle>
                    <MetaBlockContent>
                      <p>{challenge}</p>
                    </MetaBlockContent>
                  </ChallengeBlock>
                )}
                {outcome && (
                  <OutcomeBlock>
                    <MetaBlockTitle>Outcome</MetaBlockTitle>
                    <MetaBlockContent>
                      <p>{outcome}</p>
                    </MetaBlockContent>
                  </OutcomeBlock>
                )}
                {!!expertise.length && (
                  <ExpertiseBlock>
                    <MetaBlockTitle>Expertise</MetaBlockTitle>
                    <MetaBlockContent>
                      <ul>
                        {expertise.map((exp) => (
                          <li key={exp.slug}>
                            <Link
                              title='View expertise page'
                              to={`/expertise/${exp.slug}`}
                            >
                              {exp.frontmatter.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </MetaBlockContent>
                  </ExpertiseBlock>
                )}
              </ProjectMetaInner>
            </ProjectMeta>
          )}
          <BlockLayout blocks={layoutBlocks} />
          {!!related?.length && <SectionRelated related={related} />}

          <InpageNavigation
            type='project'
            topics={topics}
            prevPost={prevPost}
            nextPost={nextPost}
          />

          <CallToAction
            title='Have a challenging project that could use our help?'
            suptitle="Let's connect"
          >
            <p>We&apos;d love to hear from you.</p>
            <CTAActions>
              <Button
                variation='primary-fill'
                size='large'
                forwardedAs={Link}
                to='/contact'
              >
                Contact us
              </Button>
            </CTAActions>
          </CallToAction>
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default ProjectSingleTemplate;

ProjectSingleTemplate.propTypes = {
  data: T.object,
  location: T.object,
  pageContext: T.shape({
    id: T.string,
    next: T.object,
    previous: T.object
  })
};

export const pageQuery = graphql`
  query ProjectById($id: String!) {
    project(id: { eq: $id }) {
      id
      html
      internal {
        type
      }
      frontmatter {
        title
        lead
        teaser
        overview
        challenge
        outcome
        client {
          name
          url
        }
        media {
          ...CoverImageFluid
        }
        expertise {
          slug
          frontmatter {
            title
          }
        }
        topics
        public {
          url
        }
        source {
          title
          url
        }
        related {
          ...RelatedContent
        }
        blocks {
          ...BlockLayout
        }
      }
    }
  }
`;
